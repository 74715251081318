import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { getShiftHandoverReportDetails } from "../../services/shiftHandover.service";

export default {
  data() {
    return {
      shiftInfoError: null,
      isLoadingShiftInfo: false,
      formData: {
        shiftHandoverNo: null,
        handoverBase: {
          isSoftHandover: null,
          startTime: null,
          endTime: new Date(),
          takeoverUserId: null,
          takeoverUserName: null,
          takeoverRosterDWS: null,
          handoverUserId: null,
          handoverUserName: null,
          stationId: null,
        },
        confirmation: {
          handoverSignature: null,
          handoverSignatureId: null,
          takeoverSignature: null,
          takeOverSignatureId: null,
          remarks: null,
        },
        // cash management
        psmSummary: {
          data: null,
          discrepancyReports: [],
        },
        pscManualChangeSummary: {
          data: null,
          discrepancyReports: [],
        },
        pscCashDeclarationSummary: {
          data: null,
          discrepancyReports: [],
        },
        preEncodedTicketSummary: {
          data: null,
          discrepancyReports: [],
        },
        afcCashBagSummary: {
          data: null,
          discrepancyReports: [],
        },
        gtmCoinFloatSummary: {
          data: null,
          discrepancyReports: [],
        },
        gtmCashBoxSummary: {
          data: null,
          discrepancyReports: [],
        },
        sccfSummary: {
          data: null,
          discrepancyReports: [],
        },
        stationTransferSummary: {
          data: null,
          discrepancyReports: [],
        },
        // inventory
        smartCardSummary: {
          data: null,
          remarks: "",
        },
        // shift handover
        fitnessConfirmation: {
          data: null,
          remarks: "",
        },
        afcKeySummary: {
          data: null,
          remarks: "",
        },
        dutySMKeySummary: {
          data: null,
          remarks: "",
        },
        dutySMItemSummary: {
          data: null,
          remarks: "",
        },
        // equipment fault
        faultSummary: {
          data: null,
          remarks: "",
        },
        faultOccurrenceSummary: {
          data: null,
          remarks: "",
        },
        remarks: null,
      },
      isRejected: false,
      isLatest: false,
      isUserAbleToReject: false,
      status: null,
    };
  },
  methods: {
    isDataAligned({ discrepancyReports, remarks }) {
      return isEmpty(discrepancyReports) && isEmpty(remarks);
    },
    async loadPageData() {
      this.isLoadingShiftInfo = true;
      const resp = await getShiftHandoverReportDetails({
        id: get(this.$route.params, "shiftHandoverId", null),
      });
      if (resp.success) {
        this.setFormData(get(resp.payload, "data", {}));
        this.isLoadingShiftInfo = false;
        this.shiftInfoError = null;
      } else {
        this.isLoadingShiftInfo = false;
        this.shiftInfoError = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    setFormData(shiftHandoverData) {
      let summaryData = shiftHandoverData.data;
      const handoverType = get(shiftHandoverData, "typeEnum", 0);
      this.formData.shiftHandoverNo = get(
        shiftHandoverData,
        "shiftHandoverNo",
        null
      );
      this.formData.handoverBase.isSoftHandover = handoverType !== 1;
      this.formData.handoverBase.stationId = get(
        shiftHandoverData,
        "stationId",
        null
      );
      this.formData.handoverBase.handOverRosterDWS = get(
        shiftHandoverData,
        "handOverRosterDWS",
        null
      );
      this.formData.handoverBase.stationId = get(
        shiftHandoverData,
        "stationId",
        null
      );
      this.formData.handoverBase.stationName = get(
        shiftHandoverData,
        "stationName",
        null
      );
      this.status = get(shiftHandoverData, "status", null)
      this.isRejected = this.status === "Rejected";
      this.isLatest = get(shiftHandoverData, "isLatest", false);
      this.isUserAbleToReject = get(
        shiftHandoverData,
        "isUserAbleToReject",
        false
      );
      this.formData.handoverBase.startTime = new Date(
        get(shiftHandoverData, "startDate", new Date())
      );
      this.formData.handoverBase.endTime = new Date(
        get(shiftHandoverData, "endDate", new Date())
      );
      this.formData.confirmation.handoverSignatureId = get(
        shiftHandoverData,
        "handOverSignatureId",
        null
      );
      this.formData.confirmation.takeOverSignatureId = get(
        shiftHandoverData,
        "takeOverSignatureId",
        null
      );
      this.formData.handoverBase.handoverUserName = get(
        shiftHandoverData,
        "handOverUserName",
        null
      );
      this.formData.handoverBase.handoverUserId = get(
        shiftHandoverData,
        "handOverUserId",
        null
      );

      const takeOverUserId =
        get(shiftHandoverData, "takeOverUserId", null) || this.userId;
      const takeOverUserName =
        get(shiftHandoverData, "takeOverUserName", null) || this.userName;
      this.formData.handoverBase.takeoverUserName = takeOverUserName;
      this.formData.handoverBase.takeoverUserId = takeOverUserId;
      this.formData.psmSummary.data = get(summaryData, "psmSummary", {});
      this.formData.psmSummary.discrepancyReports = get(
        summaryData,
        "psmSummaryDiscrepancyReports",
        []
      );
      this.formData.pscManualChangeSummary.data = get(
        summaryData,
        "pscManualChangeSummary",
        {}
      );
      this.formData.pscManualChangeSummary.discrepancyReports = get(
        summaryData,
        "pscManualChangeDiscrepancyReports",
        []
      );
      this.formData.pscCashDeclarationSummary.data = get(
        summaryData,
        "pscCashDeclarationSummary",
        {}
      );
      this.formData.pscCashDeclarationSummary.discrepancyReports = get(
        summaryData,
        "pscCashDeclarationDiscrepancyReports",
        []
      );
      this.formData.preEncodedTicketSummary.data = get(
        summaryData,
        "preEncodedTicketSummary",
        {}
      );
      this.formData.preEncodedTicketSummary.discrepancyReports = get(
        summaryData,
        "preEncodedTicketDiscrepancyReports",
        []
      );
      this.formData.afcCashBagSummary.data = get(
        summaryData,
        "afcCashBagSummary",
        {}
      );
      this.formData.afcCashBagSummary.discrepancyReports = get(
        summaryData,
        "afcCashBagDiscrepancyReports",
        []
      );
      this.formData.gtmCoinFloatSummary.data = get(
        summaryData,
        "gtmCoinFloatSummary",
        {}
      );
      this.formData.gtmCoinFloatSummary.discrepancyReports = get(
        summaryData,
        "gtmCoinFloatDiscrepancyReports",
        []
      );
      this.formData.gtmCashBoxSummary.data = get(
        summaryData,
        "gtmCashBoxSummary",
        {}
      );
      this.formData.gtmCashBoxSummary.discrepancyReports = get(
        summaryData,
        "gtmCashBoxDiscrepancyReports",
        []
      );
      this.formData.sccfSummary.data = get(summaryData, "sccfSummary", {});
      this.formData.sccfSummary.discrepancyReports = get(
        summaryData,
        "sccfDiscrepancyReports",
        []
      );
      this.formData.stationTransferSummary.data = get(
        summaryData,
        "stationTransferSummary",
        {}
      );
      this.formData.stationTransferSummary.discrepancyReports = get(
        summaryData,
        "stationTransferDiscrepancyReports",
        []
      );
      this.formData.smartCardSummary.data = get(
        summaryData,
        "smartCardSummary",
        []
      );
      this.formData.smartCardSummary.remarks = get(
        summaryData,
        "smartCardRemarks",
        ""
      );
      this.formData.fitnessConfirmation.data = get(
        summaryData.fitnessConfirmation,
        "data",
        {}
      );
      this.formData.fitnessConfirmation.remarks = get(
        summaryData.fitnessConfirmation,
        "remarks",
        ""
      );
      this.formData.afcKeySummary.data = get(
        summaryData.afcStoreRoomKeyConfirmation,
        "data",
        []
      );
      this.formData.afcKeySummary.remarks = get(
        summaryData.afcStoreRoomKeyConfirmation,
        "remarks",
        ""
      );
      this.formData.dutySMKeySummary.data = get(
        summaryData.smDutyKeyConfirmation,
        "keys",
        []
      );
      this.formData.dutySMKeySummary.remarks = get(
        summaryData.smDutyKeyConfirmation,
        "remarks",
        ""
      );
      this.formData.dutySMItemSummary.data = get(
        summaryData.smDutyPhoneConfirmation,
        "items",
        []
      );
      this.formData.dutySMItemSummary.remarks = get(
        summaryData.smDutyPhoneConfirmation,
        "remarks",
        ""
      );
      this.formData.faultSummary.data = get(
        summaryData,
        "stationFaultSummary",
        []
      );
      this.formData.faultSummary.remarks = get(
        summaryData,
        "stationFaultRemarks",
        ""
      );
      this.formData.faultOccurrenceSummary.data = get(
        summaryData,
        "stationOccurrenceSummary",
        []
      );
      this.formData.faultOccurrenceSummary.remarks = get(
        summaryData,
        "stationOccurrenceRemarks",
        ""
      );
      this.formData.remarks = get(
        shiftHandoverData,
        "remarks",
        ""
      );
    },
  },
};
