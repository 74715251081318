<template>
  <div class="space-y-10">
    <asom-alert
      class="col-span-2"
      v-if="computedError"
      variant="error"
      :error-message="computedError"
    />
    <div class="w-full text-center" v-if="isLoadingShiftInfo">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else-if="!shiftInfoError">
      <shift-info
        :rosterDWS="formData.handoverBase.handOverRosterDWS"
        :stationName="formData.handoverBase.stationName"
        :startTime="formData.handoverBase.startTime"
        :endTime="formData.handoverBase.endTime"
      />
      <div class="space-y-4">
        <h3 class="subheader">
          Cash Management
        </h3>
        <psm-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.psmSummary.data"
          :isAligned="isDataAligned(formData.psmSummary)"
          :discrepancyReports="formData.psmSummary.discrepancyReports"
        />
        <psc-cash-declaration-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.pscCashDeclarationSummary.data"
          :error="formData.pscCashDeclarationSummary.error"
          :isAligned="isDataAligned(formData.pscCashDeclarationSummary)"
          :discrepancyReports="formData.pscCashDeclarationSummary.discrepancyReports"
        />
        <psc-manual-change-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.pscManualChangeSummary.data"
          :isAligned="isDataAligned(formData.pscManualChangeSummary)"
          :discrepancyReports="formData.pscManualChangeSummary.discrepancyReports"
        />
        <pre-encoded-ticket-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.preEncodedTicketSummary.data"
          :error="formData.preEncodedTicketSummary.error"
          :isAligned="isDataAligned(formData.preEncodedTicketSummary)"
          :discrepancyReports="formData.preEncodedTicketSummary.discrepancyReports"
        />
        <afc-cash-bag-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.afcCashBagSummary.data"
          :error="formData.afcCashBagSummary.error"
          :isAligned="isDataAligned(formData.afcCashBagSummary)"
          :discrepancyReports="formData.afcCashBagSummary.discrepancyReports"
        />
        <gtm-coin-float-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.gtmCoinFloatSummary.data"
          :error="formData.gtmCoinFloatSummary.error"
          :isAligned="isDataAligned(formData.gtmCoinFloatSummary)"
          :discrepancyReports="formData.gtmCoinFloatSummary.discrepancyReports"
        />
        <gtm-cash-box-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.gtmCashBoxSummary.data"
          :error="formData.gtmCashBoxSummary.error"
          :isAligned="isDataAligned(formData.gtmCashBoxSummary)"
          :discrepancyReports="formData.gtmCashBoxSummary.discrepancyReports"
        />
        <sccf-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.sccfSummary.data"
          :error="formData.sccfSummary.error"
          :isAligned="isDataAligned(formData.sccfSummary)"
          :discrepancyReports="formData.sccfSummary.discrepancyReports"
        />
        <station-transfer-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.stationTransferSummary.data"
          :error="formData.stationTransferSummary.error"
          :isAligned="isDataAligned(formData.stationTransferSummary)"
          :discrepancyReports="formData.stationTransferSummary.discrepancyReports"
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">
          Inventory Management
        </h3>
        <smart-card-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.smartCardSummary"
          :error="formData.smartCardSummary.error"
          :isAligned="isDataAligned(formData.smartCardSummary)"
          :remarks="formData.smartCardSummary.remarks"
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">
          Shift Handover Manual Check
        </h3>
        <fitness-confirmation
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.fitnessConfirmation.data"
          :error="formData.fitnessConfirmation.error"
          :isAligned="isDataAligned(formData.fitnessConfirmation)"
          :remarks="formData.fitnessConfirmation.remarks"
        />
        <afc-key-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.afcKeySummary.data"
          :error="formData.afcKeySummary.error"
          :isAligned="isDataAligned(formData.afcKeySummary)"
          :remarks="formData.afcKeySummary.remarks"
        />
        <duty-sm-key-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.dutySMKeySummary.data"
          :error="formData.dutySMKeySummary.error"
          :isAligned="isDataAligned(formData.dutySMKeySummary)"
          :remarks="formData.dutySMKeySummary.remarks"
        />
        <duty-sm-item-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.dutySMItemSummary.data"
          :error="formData.dutySMItemSummary.error"
          :isAligned="isDataAligned(formData.dutySMItemSummary)"
          :remarks="formData.dutySMItemSummary.remarks"
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">
          Equipment Faults
        </h3>
        <fault-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.faultSummary.data"
          :error="formData.faultSummary.error"
          :isAligned="isDataAligned(formData.faultSummary)"
          :remarks="formData.faultSummary.remarks"
        />
        <fault-occurrence-summary
          :readonly="true"
          :loading="isLoadingShiftInfo"
          :data="formData.faultOccurrenceSummary.data"
          :error="formData.faultOccurrenceSummary.error"
          :isAligned="isDataAligned(formData.faultOccurrenceSummary)"
          :remarks="formData.faultOccurrenceSummary.remarks"
        />
      </div>
      <asom-card title="Confirmation">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field disabled label="Remarks">
            <asom-input-textarea disabled v-model="formData.remarks" />
          </asom-form-field>
          <div></div>
          <div>
            <asom-form-field label="Handover">
              <asom-input-text
                :modelValue="formData.handoverBase.handoverUserName"
                disabled
              />
            </asom-form-field>
            <asom-form-field
              label="Signature (Handover)"
              error="Signature is required"
              :state="inputStates('formData.confirmation.handoverSignature')"
            >
              <asom-signature-view
                v-if="formData.confirmation.handoverSignatureId"
                :fileId="formData.confirmation.handoverSignatureId"
              />
            </asom-form-field>
          </div>
          <div v-if="isTakeoverAllowed">
            <asom-form-field label="Takeover">
              <asom-input-text
                :modelValue="formData.handoverBase.takeoverUserName"
                disabled
              />
            </asom-form-field>
            <asom-form-field
              label="Signature (Takeover)"
              error="Signature is required"
              :state="inputStates('formData.confirmation.takeoverSignature')"
            >
              <asom-signature-pad
                :width="300"
                v-model="formData.confirmation.takeoverSignature"
                :state="inputStates('formData.confirmation.takeoverSignature')"
              />
            </asom-form-field>
          </div>
        </div>
        <div class="flex justify-end space-x-2 mt-10">
          <asom-button
            outline
            text="Back"
            variant="secondary"
            @click="onBackClicked"
          />
          <asom-button
            outline
            v-if="isRejectionAllowed"
            :isLoading="isRejecting"
            :disabled="isRejecting"
            text="Reject Shift Handover"
            variant="error"
            @click="confirmRejection"
          />
          <asom-button
            v-if="isTakeoverAllowed"  
            text="Confirm Takeover"
            variant="primary"
            @click="onSubmitBtnClicked"
          />
        </div>
      </asom-card>
      <asom-modal title="Confirm Rejection" v-model="showRejectionModal">
        <div class="space-y-4">
          <p>Please note that rejecting the Shift Handover would require for Handover SM to perform Shift Handover again.</p>
          <p>
            Do you want to reject shift handover
            <strong>{{ formData.shiftHandoverNo }}</strong
            >?
          </p>
          <div class="flex justify-end space-x-2 mt-10">
            <asom-button
              outline
              :disabled="isRejecting"
              text="Cancel"
              variant="secondary"
              @click="cancelRejection"
            />
            <asom-button
              outline
              :isLoading="isRejecting"
              :disabled="isRejecting"
              text="Confirm"
              variant="error"
              @click="rejectShiftHandover"
            />
          </div>
        </div>
      </asom-modal>
    </template>
  </div>
</template>

<script>
import get from "lodash.get";
import isEmpty from 'lodash.isempty';
import { provide } from "vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { uploadFile } from "@/services/file.service";
import { imageDataToBlob } from "@/helpers/imageDataToBlob";
import ShiftInfo from "./components/_ShiftInfo.vue";
import PSMSummary from "./components/PSMSummary.vue";
import PSCCashDeclarationSummary from "./components/PSCCashDeclarationSummary.vue";
import PSCManualChangeSummary from "./components/PSCManualChangeSummary.vue";
import PreEncodedTicketSummary from "./components/PreEncodedTicketSummary.vue";
import AFCCashBagSummary from "./components/AFCCashBagSummary.vue";
import GTMCoinFloatSummary from "./components/GTMCoinFloatSummary.vue";
import GTMCashBoxSummary from "./components/GTMCashBoxSummary.vue";
import SCCFSummary from "./components/SCCFSummary.vue";
import StationTransferSummary from "./components/StationTransferSummary.vue";
import FitnessConfirmation from "./components/FitnessConfirmation.vue";
import SmartCardSummary from "./components/SmartCardSummary.vue";
import AFCKeySummary from "./components/AFCKeySummary.vue";
import DutySMKeySummary from "./components/DutySMKeySummary.vue";
import DutySMItemSummary from "./components/DutySMItemSummary.vue";
import FaultSummary from "./components/FaultSummary.vue";
import FaultOccurrenceSummary from "./components/FaultOccurrenceSummary.vue";
import {
  confirmTakeover,
} from "../../services/shiftHandover.service";
import detailsMixin from "./_shiftHandoverDetailsMixin";
import { postAsync } from "../../services/serviceHandlers";
import { REJECT_SHIFT_HANDOVER } from "../../constants/apis";
import { ATTACHMENT_CATEGORIES } from "../../constants/APIEnums/attachmentEnums";

export default {
  mixins: [ detailsMixin, inputStates ],
  components: {
    ShiftInfo,
    "psm-summary": PSMSummary,
    "psc-manual-change-summary": PSCManualChangeSummary,
    PreEncodedTicketSummary,
    "afc-cash-bag-summary": AFCCashBagSummary,
    "gtm-coin-float-summary": GTMCoinFloatSummary,
    "gtm-cash-box-summary": GTMCashBoxSummary,
    "sccf-summary": SCCFSummary,
    StationTransferSummary,
    SmartCardSummary,
    FitnessConfirmation,
    "afc-key-summary": AFCKeySummary,
    "duty-sm-key-summary": DutySMKeySummary,
    "duty-sm-item-summary": DutySMItemSummary,
    FaultSummary,
    FaultOccurrenceSummary,
    "psc-cash-declaration-summary": PSCCashDeclarationSummary,
  },
  setup() {
    const v$ = useVuelidate();
    provide("v$", v$);
    return {
      v$,
    };
  },
  data() {
    return {
      submissionError: null,
      isSubmitting: false,
      showRejectionModal: false,
    };
  },
  validations() {
    let rules = {
      formData: {
        handoverBase: {
          takeoverUserId: { required },
        },
      },
    };
    if (!this.formData.handoverBase.isSoftHandover) {
      rules.formData['confirmation'] = {
        takeoverSignature: { required }
      }
    }
    return rules;
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      userName: "auth/userName",
      rosterDWS: "currentRoster/currentRosterShift",
      rosterStationId: "currentRoster/currentStationId",
      rosterStationName: "currentRoster/currentStationName",
    }),
    computedError() {
      return this.shiftInfoError || this.submissionError;
    },
    isTakeoverAllowed(){
      return (this.formData.confirmation.takeOverSignatureId === null && 
        this.formData.handoverBase.handoverUserId !== this.userId);
    },
    isRejectionAllowed(){
      return this.isLatest && this.isUserAbleToReject;
    },
  },
  mounted() {
    if (
      this.rosterDWS !== null &&
      this.rosterStationId !== null &&
      this.rosterStationName !== null
    ) {
      this.loadPageData();
    }
  },
  methods: {
    get,
    onBackClicked() {
      this.$router.go(-1);
    },
    onSubmitBtnClicked() {
      if (this.isSubmitting) return;
      this.submissionError = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        this.onSubmit();
      } else {
        this.submissionError = "Please complete all required fields";
        if(get(this.v$, "formData.confirmation.takeoverSignature.$invalid")){
          this.submissionError = "Signature is required";
        }
        this.$scrollTop();
      }
    },
    confirmRejection() {
      this.showRejectionModal = true;
    },
    cancelRejection() {
      this.showRejectionModal = false;
    },
    async rejectShiftHandover() {
      if (this.isRejecting) return;
      this.isRejecting = true;
      var id = get(this.$route.params, "shiftHandoverId", null);
      var result = await postAsync({
        url: REJECT_SHIFT_HANDOVER,
        body: {
          shiftHandoverId: id,
        },
      });
      if (result && result.success) {
        this.rejectionError = null;
        this.onBackClicked();
      } else {
        this.rejectionError = result.payload;
      }
      this.isRejecting = false;
    },
    async onSubmit() {
      this.isSubmitting = true;
      console.log(this.formData.confirmation);
      const isSignatureUploaded = !isEmpty(this.formData.confirmation.takeOverSignatureId);
      console.log(isSignatureUploaded);
      if (!isSignatureUploaded) {
        const signatureBlob = imageDataToBlob(
          this.formData.confirmation.takeoverSignature,
          "takeover_signature"
        );
        const uploadResult = await uploadFile([signatureBlob], ATTACHMENT_CATEGORIES.SHIFT_HANDOVER);
        if (!uploadResult.success) {
          this.submissionError = uploadResult.payload;
          this.isSubmitting = false;
          this.$scrollTop();
          return;
        }
        this.formData.confirmation.takeoverSignatureId = get(
          uploadResult,
          "payload.files.0.fileId"
        );
      }
      const result = await confirmTakeover({
        shiftHandoverId: get(this.$route.params, "shiftHandoverId", null),
        takeoverRosterDWS: this.formData.rosterDWS,
        takeoverSignatureId: this.formData.confirmation.takeoverSignatureId,
        remarks: this.formData.confirmation.remarks,
      });
      if (result.success) {
        this.$router.go(-1);
      } else {
        this.submissionError = result.payload;
        this.$scrollTop();
      }
      this.isSubmitting = false;
    },
  },
};
</script>
